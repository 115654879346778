var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"ma-auto",attrs:{"justify-center":"","wrap":"","row":"","align-center":""}},[_c('v-container',[_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',{staticClass:"title"},[_vm._v(" Choose your topics of interest ")])])],1),_c('v-row',{staticClass:"ma-auto"},_vm._l((_vm.topics),function(item){return _c('v-col',{key:item.wd,attrs:{"cols":"12","md":"2","sm":"4"}},[_c('v-item',{attrs:{"value":item.wd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{on:{"click":toggle}},[_c('v-img',{attrs:{"src":((item.image) + "?width=300"),"height":"100","width":"100%","cover":""}},[_c('v-row',{staticClass:"fill-height align-end"},[_c('v-col',{staticClass:"px-2 py-0 ma-0"},[_c('v-card-title',{staticClass:"pa-0 grey darken-3",staticStyle:{"opacity":"0.85"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(" "+_vm._s(active ? "mdi-heart" : "mdi-heart-outline")+" ")])],1),_c('h3',{staticClass:"title mb-0 overflow-y-hidden white--text",domProps:{"textContent":_vm._s(item.name)}})],1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1),_c('v-progress-linear',{attrs:{"active":!_vm.loaded,"indeterminate":!_vm.loaded,"color":"blue","absolute":"","top":""}}),_c('v-layout',{staticClass:"ma-auto",attrs:{"justify-center":"","wrap":"","row":"","align-center":""}},[_c('v-container',[(_vm.task === 'explore')?_c('h2',{staticClass:"title"},[_vm._v(" Articles from selected topic ")]):_vm._e(),(_vm.task === 'translate')?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.selectedTopic)+" articles to translate from "+_vm._s(_vm.autonym(_vm.contentLanguage))+" to "+_vm._s(_vm.autonym(_vm.targetLanguage))+" ")]):_vm._e(),_c('v-row',_vm._l((_vm.articles),function(article){return _c('v-col',{key:article.item.value,attrs:{"cols":"12","md":"3","sm":"4"}},[_c('v-card',{staticClass:"ma-2",attrs:{"height":"300px","to":_vm.task === 'explore' &&
                ("/page/" + _vm.contentLanguage + "/" + (article.itemLabel.value)),"href":_vm.task === 'translate' &&
                ("https://en.wikipedia.org/wiki/Special:CX?page=" + (article.itemLabel.value) + "&from=" + _vm.contentLanguage + "&to=" + _vm.targetLanguage)}},[_c('v-img',{attrs:{"lazy-src":require('@/assets/Wikipedia-logo-version-2.svg?lazy'),"src":article.image
                  ? ((article.image.value) + "?width=300")
                  : require('@/assets/Wikipedia-logo-version-2.svg?lazy'),"cover":"","width":"100%","height":"300px","position":"center 33%"}},[_c('v-row',{staticClass:"fill-height  align-end ",staticStyle:{"opacity":"0.85"}},[_c('v-col',{staticClass:"px-2 py-0 ma-0"},[_c('v-card-title',{staticClass:"pa-0 px-2 grey darken-3 white--text overflow-hidden text-no-wrap"},[_c('h3',{staticClass:"title overflow-hidden ",domProps:{"textContent":_vm._s(article.itemLabel.value)}})]),(article.itemDescription)?_c('v-card-text',{staticClass:"extract px-2 text-xs-left overflow-hidden text-no-wrap grey darken-3 white--text"},[_c('h4',[_vm._v(_vm._s(article.itemDescription.value))])]):_vm._e()],1)],1)],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }